import {AfterContentInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Logger} from '../../util/logger';
import {StandaloneComponent} from '../standalone.component';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {ViewProfileComponent} from '../view-profile/view-profile.component';
import {AppConstants} from '../AppConstants';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AgreementService, RecoveryEmailService, TotpService, User} from '../../gen';
import {FormIntactChecker} from '../FormIntactChecker';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CountriesService} from '../countries-service';
import {RegionService} from '../region-service';
import {CanComponentDeactivate} from '../can-deactivate-guard.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition, createTabSlideTransitionSteps} from '../animations';
import {AnimationBuilder, AnimationFactory} from '@angular/animations';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService} from '../app-state.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
const cloneDeep = require('lodash.clonedeep');

interface Item {
  value: string;
  label: string;
}
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition,
  ],
})
export class EditProfileComponent extends ViewProfileComponent implements OnInit, OnDestroy, CanComponentDeactivate, AfterContentInit {
  personalFields;
  contactFields;
  loginFields;
  totpActive = false;
  profileForm: FormGroup;
  submitFailed: boolean;
  countryList: Item[];
  tabs: String[] = ['profile', 'contact' , 'login'];
  activeTab: String = 'profile';
  skipUnloadNotification: boolean;
  regionOptions: Item[];
  regionOptionsCountry: string|undefined;
  /**
   * Seems that there is no way to check if a modal is open or not, so we must implement this missing basic functionality our self.
   */
  modalIsOpen = false;
  // Really don't see why I need this here, no mention anywhere in the docs.
  modal: NgbModalRef;
  enabledLabel = '';
  disabledLabel = '';
  hasRecoveryField: boolean;
  @ViewChild('tabHolder') tabHolder: ElementRef;
  @ViewChild('profileTab') profileTab: ElementRef;
  @ViewChild('loginTab') loginTab: ElementRef;
  @ViewChild('contactTab') contactTab: ElementRef;
  @ViewChild('confirmUnsavedChangesNavigation', { static: true }) confirmUnsavedChangesNavigation: ElementRef;
  private profileFormChecker: FormIntactChecker;
  private personalFormGroupChecker: FormIntactChecker;
  private contactFormGroupChecker: FormIntactChecker;
  private loginFormGroupChecker: FormIntactChecker;


  constructor(
              @Inject('AgreementApi') protected agreementApi: AgreementService,
              private animationBuilder: AnimationBuilder,
              appStateService: AppStateService,
              route: ActivatedRoute,
              router: Router,
              countries: CountriesService,
              regions: RegionService,
              @Inject('RecoveryEmailApi') private recoveryEmailApi: RecoveryEmailService,
              @Inject('TotpApi') private totpApi: TotpService,
              private modalService: NgbModal,
              translate: TranslateService,
              alertHandler: AlertHandler,
              logger: Logger,
              hostElement: ElementRef,
              configuration: ConfigurationService
  ) {
    super(
      agreementApi,
      appStateService,
      route,
      router,
      countries,
      regions,
      logger,
      translate,
      alertHandler,
      hostElement,
      configuration);
    this.personalFields = cloneDeep(this.configuration.getProperties().profilePersonalFields);
    this.contactFields = cloneDeep(this.configuration.getProperties().profileContactFields);
    this.loginFields = cloneDeep(this.configuration.getProperties().profileLoginFields);

    this.hasRecoveryField = this.configuration.getProperties().enableRecoveryEmails;

    this.focusSelectorPrefix = '.card-body .tab-content .active';
    if (this.contactFields.length === 0) {
      this.tabs = this.tabs.filter((val) => val !== 'contact');
    }
    this.route.fragment.subscribe(p => {
      if (p && this.tabs.indexOf(p) >= 0) {
        this.activeTab = p;
      }
    });
    const personalControls = {};
    for (let i = 0; i < this.personalFields.length; i += 1) {
      const validators = [];
      const f = this.personalFields[i];
      if (f.required) {
        validators.push(Validators.required);
      }
      personalControls[f.key] = new FormControl('', validators);
    }

    const contactControls: any = {};
    const contactAddressControls = {};
    for (let i = 0; i < this.contactFields.length; i += 1) {
      const validators = [];
      const f = this.contactFields[i];
      if (f.required) {
        if (f.key === 'address-country') {
          validators.push(Validators.pattern(AppConstants.NOT_NULL_PATTERN));
        } else {
          validators.push(Validators.required);
        }
      }
      if (f.key === 'phoneNumber') {
        contactControls[f.key] = new FormControl('', validators);
      }  else if (f.key ===  'address-region') {
        contactAddressControls['region'] = new FormControl(null, validators);
      } else {
        contactAddressControls[f.key.replace('address-', '')] = new FormControl(f.key === 'address-country' ? null : '', validators);
      }
    }
    contactControls.address = new FormGroup(contactAddressControls);


    const loginControls = {};
    for (let i = 0; i < this.loginFields.length; i += 1) {
      const f = this.loginFields[i];
      // these are not directly editable, so no need for validators
      loginControls[f.key] = new FormControl(f.key === 'password' ? '**********' : '', []);
    }
    if (this.hasRecoveryField) {
      loginControls['recoveryEmail'] = new FormControl('', []);
    }

    this.profileForm = new FormGroup({
      personal: new FormGroup(personalControls),
      contact: new FormGroup(contactControls),
      login: new FormGroup(loginControls),
    });
    this.beforeunloadHandler = this.beforeunloadHandler.bind(this);
    this.validateRegion = this.validateRegion.bind(this);
    this.profileForm.get('contact').setValidators([this.validateRegion]);
  }
  showRegion() {
    return this.regionOptions?.length > 1;
  }
  resolveRegionOptions(obj?: Item) {
    const country = this.resolveField('contact.address.country')?.value;
    if (this.regionOptionsCountry !== country) {
      this.regionOptionsCountry = country;
      if (!!country) {
        this.regions.alphabetical(country).subscribe(res => {
          const t = res?.map((n) => ({label: n.name, value: n.code}));
          this.regionOptions = t || [];
          if (obj) {
            this.regionOptions.unshift(obj);
          }
          const region = this.resolveField('contact.address.region');
          if (region?.value &&
            AppConstants.NOT_NULL_PATTERN.test(region?.value) &&
            !this.regionOptions.find((f) => {
              return f.value === region?.value;
            })) {
            region.setValue(null);
          }
        });
      }
    }
  }
  private validateRegion(control: AbstractControl): ValidationErrors | null {
    this.resolveRegionOptions({ label: this.resolveSelectRegionLabel(), value: null});
    const regionField = this.resolveField('contact.address.region');
    let retVal;
    if (this.regionOptions?.length > 1) {
      // If there are selectable region options, the region is required
      retVal = !!regionField.value && AppConstants.NOT_NULL_PATTERN.test(regionField.value)
        ? null
        : { regionRequired: true };
    } else {
      retVal = null;
    }
    return retVal;
  }
  changeTab(t: string) {
    if (t && t !== this.activeTab && this.tabs.indexOf(t) >= 0) {
      const oldTab = this.activeTab;
      const newTab = t;
      this.activeTab = t;
      if (!this.disableAnimations()) {
        let animationFactory: AnimationFactory;
        animationFactory = this.animationBuilder.build(
          createTabSlideTransitionSteps(this[oldTab + 'Tab'], this[newTab + 'Tab'], 300)
        );
        const anim: any = animationFactory.create(this.tabHolder.nativeElement);
        anim.onDone(() => {
          anim.reset();
          StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, (t === 'login' ? '.card-footer' : this.focusSelectorPrefix));
        });
        anim.play();
      } else {
        StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, (t === 'login' ? '.card-footer' : this.focusSelectorPrefix));
      }
    }
  }
  ngAfterContentInit() {
    setTimeout(() => {
      StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, this.activeTab === 'login' ? '.card-footer' : this.focusSelectorPrefix);
    }, 1);
    this.initialized.emit(true);
  }
  onProfileLoaded(response: User) {
    super.onProfileLoaded(response);
    if (this.profile) {
      //
      if (this.personalFields.findIndex((val) => val.key === 'firstName') >= 0) {
        this.resolveField('personal.firstName').setValue(this.profile.firstName || '');
      }
      if (this.personalFields.findIndex((val) => val.key === 'lastName') >= 0) {
        this.resolveField('personal.lastName').setValue(this.profile.lastName || '');
      }
      if (this.personalFields.findIndex((val) => val.key === 'nickname') >= 0) {
        this.resolveField('personal.nickname').setValue(this.profile.nickname || '');
      }
      if (this.personalFields.findIndex((val) => val.key === 'preferredUsername') >= 0) {
        this.resolveField('personal.preferredUsername').setValue(this.profile.preferredUsername || '');
      }
      if (this.personalFields.findIndex((val) => val.key === 'professionalTitle') >= 0) {
        this.resolveField('personal.professionalTitle').setValue(this.profile.professionalTitle || '');
      }
      if (this.loginFields.findIndex((val) => val.key === 'email') >= 0) {
        this.resolveField('login.email').setValue(this.profile.email || '');
      }
      if (this.hasRecoveryField) {
        this.recoveryEmailApi.listRecoveryEmails('response').subscribe((response2) => {
          if (response2.body && response2.body.length > 0) {
            const sorted = response2.body.sort((a, b) => {
              let retVal = 0;
              if (!!a.validatedAt && !b.validatedAt) {
                retVal = -1;
              } else if (!a.validatedAt && !!b.validatedAt) {
                retVal = 1;
              }
              return retVal;
            });
            this.resolveField('login.recoveryEmail').setValue(sorted.map((v) => v.value).join(', '));
          } else {
            this.resolveField('login.recoveryEmail').setValue('');
          }
        }, (er) => {
          this.resolveField('login.recoveryEmail').setValue('');
        });
      }
      if (this.contactFields.findIndex((val) => val.key === 'phoneNumber') >= 0) {
        this.resolveField('contact.phoneNumber').setValue(this.profile.phoneNumber || '');
      }
      if (this.profile.address) {
        if (this.contactFields.findIndex((val) => val.key === 'address-country') >= 0) {
          this.resolveField('contact.address.country').setValue(this.profile.address.country || '');
        }
        if (this.contactFields.findIndex((val) => val.key === 'address-streetAddress') >= 0) {
          this.resolveField('contact.address.streetAddress').setValue(this.profile.address.streetAddress || '');
        }
        if (this.contactFields.findIndex((val) => val.key === 'address-locality') >= 0) {
          this.resolveField('contact.address.locality').setValue(this.profile.address.locality || '');
        }
        if (this.contactFields.findIndex((val) => val.key === 'address-region') >= 0) {
          this.resolveField('contact.address.region').setValue(
            this.profile.address.region && this.profile.address.country ?
              this.profile.address.country + '-' + this.profile.address.region : null);
        }
        if (this.contactFields.findIndex((val) => val.key === 'address-postalCode') >= 0) {
          this.resolveField('contact.address.postalCode').setValue(this.profile.address.postalCode || '');
        }
      }
      this.profileFormChecker = new FormIntactChecker(this.profileForm);
      this.profileFormChecker.markIntact();
      this.personalFormGroupChecker = new FormIntactChecker(this.resolveField('personal') as FormGroup);
      this.personalFormGroupChecker.markIntact();
      this.contactFormGroupChecker = new FormIntactChecker(this.resolveField('contact') as FormGroup);
      this.contactFormGroupChecker.markIntact();
      this.loginFormGroupChecker = new FormIntactChecker(this.resolveField('login') as FormGroup);
      this.loginFormGroupChecker.markIntact();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.hasDelegatedAuthentication) {
      this.profileForm.disable();
    }
    this.skipUnloadNotification = false;
    this.countries.alphabetical().subscribe(res => {
      this.countryList = res.map((n) => ({label: n.name, value: n.code}));
    });
    window.addEventListener('beforeunload', this.beforeunloadHandler);
    this.translate.get(_('edit-profile.totp.enabled-label')).subscribe((res) => {
      this.enabledLabel = res;
    }, err => {
      this.enabledLabel = 'edit-profile.totp.enabled-label';
    });
    this.translate.get(_('edit-profile.totp.disabled-label')).subscribe((res) => {
      this.disabledLabel = res;
    }, err => {
      this.disabledLabel = 'edit-profile.totp.disabled-label';
    });
    if (this.loginFields.findIndex((val) => val.key === 'totp') >= 0) {
      this.totpApi.getTotpConfiguration('response', false).subscribe(
        response => {
          if (response && response.body) {
            this.totpActive = response.body.activated;
            this.resolveField('login.totp').setValue(this.totpActive ? this.enabledLabel : this.disabledLabel);
          }
        }, err => {
          this.totpActive = false;
          this.resolveField('login.totp').setValue(this.totpActive ? this.enabledLabel : this.disabledLabel);
        }
      );
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    window.removeEventListener('beforeunload', this.beforeunloadHandler);
  }

  beforeunloadHandler(e) {
    if (this.profileForm.dirty && !this.skipUnloadNotification) {
      (e || window.event).preventDefault();
      // Seems that this copy is never shown anywhere, yet required(?) for the navigation blocker to work
      (e || window.event).returnValue = this.translate.get('edit-profile.unsaved-changes-dialog.info-paragraph');
    }
  }

  canDeactivate(nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (nextState && nextState.url) {
      const urlParts = nextState.url.split('?');
      if (urlParts.length === 2 &&
        urlParts[0] === AppConstants.PATH_LOGOUT &&
        (nextState.root.queryParams.force === 'true' || nextState.root.queryParams[AppConstants.QP_FORCE] === true)) {
        this.skipUnloadNotification = true;
      }
    }
    if (this.profileForm.dirty && !this.skipUnloadNotification) {
      if (!this.modalIsOpen) {
        this.modal = this.modalService.open(this.confirmUnsavedChangesNavigation, {ariaLabelledBy: 'modal-basic-title'});
        this.modalIsOpen = true;
      }
      return this.modal
        .result
        .then((result) => {
          this.modalIsOpen = false;
          return result !== 'cancel';
        })
        .catch(err => {
          // no idea what this means in regards to the modals open state, let's hope this means it's not open.
          this.modalIsOpen = false;
          return false;
        })
      ;
    } else {
      return true;
    }
  }

  resolveCountryOptions(obj?: Item): Item[] {
    let t = null;
    if (this.countryList) {
      t = [].concat(this.countryList);
      if (obj) {
        t.unshift(obj);
      }
    }
    return t;
  }
  isFieldInvalid(field: string): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  isInputInvalid(f: string): boolean {
    const field: AbstractControl = this.resolveField(f);
    return field.invalid && field.touched;
  }

  isFieldValid(field: string): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  isInputValid(f: string): boolean {
    const field: AbstractControl = this.resolveField(f);
    return field.valid && field.touched;
  }

  hasError(field?: string, code?: string): boolean {
    return !field ? this.submitFailed === true : false;
  }
  cancel() {
    this.skipUnloadNotification = true;
    this.router.navigate([AppConstants.PATH_PROFILE], {
      relativeTo: this.route,
    }).then(() => {
      this.skipUnloadNotification = false;
    }, () => {
      this.skipUnloadNotification = false;
    });
  }
  resolveFieldValueForIncludedField(field: string): string | undefined {
    let retVal: string|undefined;
    const t = field.split('.');
    let fieldName;
    if (t.length > 2) {
      const t2 = [];
      for (let i = 1; i < t.length; i += 1) {
        t2.push(t[i]);
      }
      fieldName = t2.join('-');
    } else {
      fieldName = t[1];
    }
    let fields;
    if (t[0] === 'personal') {
      fields = this.personalFields;
    } else if (t[0] === 'login') {
      fields = this.loginFields;
    } else if (t[0] === 'contact') {
      fields = this.contactFields;
    }
    if (fieldName && fields && fields.findIndex((val) => val.key === fieldName) >= 0) {
      retVal = fieldName === 'address-region' ? this.resolveField(field).value?.split('-').pop() : this.resolveField(field).value;
    }
    return retVal;
  }
  removeUndefined(obj: any): any {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      if (obj[keys[i]] === undefined) {
        delete obj[keys[i]];
      }
    }
    return obj;
  }
  onSubmit() {
    this.skipUnloadNotification = true;
    this.submitFailed = false;
    const profileObj: User = this.removeUndefined({
      firstName: this.resolveFieldValueForIncludedField('personal.firstName'),
      lastName: this.resolveFieldValueForIncludedField('personal.lastName'),
      nickname: this.resolveFieldValueForIncludedField('personal.nickname'),
      preferredUsername: this.resolveFieldValueForIncludedField('personal.preferredUsername'),
      professionalTitle: this.resolveFieldValueForIncludedField('personal.professionalTitle'),
      email: this.resolveFieldValueForIncludedField('login.email'),
      phoneNumber: this.resolveFieldValueForIncludedField('contact.phoneNumber'),
      address: this.removeUndefined({
        country: this.resolveFieldValueForIncludedField('contact.address.country'),
        streetAddress: this.resolveFieldValueForIncludedField('contact.address.streetAddress'),
        locality: this.resolveFieldValueForIncludedField('contact.address.locality'),
        region: this.resolveFieldValueForIncludedField('contact.address.region'),
        postalCode: this.resolveFieldValueForIncludedField('contact.address.postalCode'),
      }),
      userProperties: this.profile.userProperties,
    });
    this.appStateService.updateUser(profileObj).subscribe(response => {
      this.router.navigate([AppConstants.PATH_PROFILE], {
        relativeTo: this.route,
      }).then(() => {
        this.skipUnloadNotification = false;
      }, () => {
        this.skipUnloadNotification = false;
      });
    }, error => {
      this.skipUnloadNotification = false;
      this.submitFailed = true;
    });
  }

  changeEmail() {
    this.router.navigate([AppConstants.PATH_MANAGE_EMAILS], {
      relativeTo: this.route,
    });
    return false;
  }
  changePWD() {
    this.router.navigate([AppConstants.PATH_CHANGE_PASSWORD], {
      relativeTo: this.route,
    });
  }
  configureTotp() {
    this.router.navigate([AppConstants.PATH_CONFIGURE_TOTP], {
      relativeTo: this.route,
    });
  }


  private resolveField(s: string): AbstractControl {
    let retVal: AbstractControl = null;
    if (s.indexOf('.')) {
      const t = s.split('.');
      retVal = this.profileForm;
      for (let i = 0; i < t.length; i++) {
        retVal = retVal.get(t[i]);
      }
    }
    return retVal;
  }
  public resolveSelectCountryLabel(): any {
    return this.translate.instant(_('edit-profile.country.select-option'));
  }
  public resolveSelectRegionLabel(): any {
    return this.translate.instant(_('edit-profile.region.select-option'));
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get(_('edit-profile.window-title'));
  }
}
