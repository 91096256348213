import {HttpErrorResponse, HttpResponseBase} from '@angular/common/http';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormGroupName, ValidationErrors, Validators} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {Observable, Subscription, timer} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Logger} from 'src/util/logger';
import ResponseUtil from 'src/util/responseUtil';
import {PasswordService} from '../../gen/api/password.service';
import {Credentials} from '../../gen/model/credentials';
import {UserNameAndPasswordChallenge} from '../../gen/model/userNameAndPasswordChallenge';
import {UserNameAndPasswordCredentialForResetPassword} from '../../gen/model/userNameAndPasswordCredentialForResetPassword';
import {AppConstants} from '../AppConstants';
import {LoginBaseComponent} from '../login-base.component';
import {ParamsService} from '../params.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {
  cardFooterFeedbackTransition,
} from '../animations';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {ProcessingIndicatorService} from '../processing-indicator.service';

interface ActivateComponentParams {
  username: string;
  activationKey: string;
}

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: [
    './activate.component.scss',
  ],
  viewProviders: [
    { provide: FormGroupName, useFactory: () => null },
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition
  ],
})
export class ActivateComponent extends LoginBaseComponent implements OnInit, OnDestroy {

  private navigationEndSubscription: Subscription;


  private invalidPasswordError: UserNameAndPasswordChallenge;
  private invalidCodeError: boolean;
  private tooManyAttemptsError: boolean;
  private tooManyAttemptsTimeoutCounter: any;
  private tooManyAttemptsTimeout: number;
  private genericError: boolean;
  private destroyed = false;

  showCodeInput: boolean;

  activateUserForm: FormGroup;

  constructor(
    @Inject('PasswordApi') private passwordApi: PasswordService,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    private alertHandler: AlertHandler,
    private processingService: ProcessingIndicatorService,
    private logger: Logger,
    hostElement: ElementRef,
    configuration: ConfigurationService
  ) {

    super(route, router, hostElement, configuration);
    this.focusSelectorPrefix = '.form-group';
    this.activateUserForm = new FormGroup({
      password: new FormControl(
        '', [Validators.required, Validators.pattern(configuration.getProperties().passwordValidation)]),
      confirmPassword: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.activateUserForm.setValidators([this.validateConfirmPassword]);
    this.showCodeInput = this.configuration.getProperties().showActivateUserCodeInput;
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.destroyed = true;
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
      this.navigationEndSubscription = undefined;
    }
  }
  continueFromInvalidActivation() {
    const nav: ParamsForRouteNav|string|undefined = this.appStateService.getAppState().resolveNextRoute(
       ['*']);
    if (typeof nav === 'string') {
      this.processingService.show();
      window.location.href = nav as string;
    } else if (nav) {
      this.router.navigate([nav.path], {
        queryParams: { ...nav.queryParams },
        fragment: nav.fragment,
      });
    }
  }
  cancelActivation() {
    if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      // keep params, except the ones not needed elsewhere
      this.router.navigate([AppConstants.PATH_LOGIN], {
        relativeTo: this.route,
        queryParams: {
          [AppConstants.QP_USER_ACTIVATION_KEY]: undefined,
        },
        queryParamsHandling: 'merge'
      });
    } else if (!this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      this.router.navigate([AppConstants.PATH_LOGOUT], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([AppConstants.PATH_PROFILE], {
        relativeTo: this.route,
      });
    }
  }

  protected init() {
    super.init();
    this.readParams();
    // looks like this is not activated when this is the initial route. The event has likely already passed when the listener is added
    this.navigationEndSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && e.url.indexOf(AppConstants.PATH_ACTIVATE) === 0) {
        const handler = () => {
          this.reset();
          this.readParams();
        };
        if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
          this.logger.debug('Activate component init with session, should this happen?');
        } else {
          handler();
        }
      }
    });
  }

  hasSession() {
    return this.appStateService.getAppState().getAuthenticationState().hasSession();
  }

  showInvalidCodeAlert(): boolean {
    return this.invalidCodeError;
  }

  showInvalidPasswordAlert(): boolean {
    return this.invalidPasswordError !== undefined;
  }

  showActivationFailed(): boolean {
    return this.genericError === true;
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  isInputInvalid(field: AbstractControl): boolean {
    if (field === this.activateUserForm.get('confirmPassword')) {
      return (field.invalid ||
        (this.activateUserForm.invalid && this.activateUserForm.errors && this.activateUserForm.errors.mustMatch)) && field.touched;

    } else if (field === this.activateUserForm.get('password'))  {
      return field.invalid && field.touched;
    } else {
      return field.invalid;
    }
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  isInputValid(field: AbstractControl): boolean {
    if (field === this.activateUserForm.get('confirmPassword')) {
      return field.valid
        && (this.activateUserForm.valid || !this.activateUserForm.errors || !this.activateUserForm.errors.mustMatch)
        && field.touched;
    } else if (field === this.activateUserForm.get('password')) {
      return field.valid && field.touched;
    } else {
      return field.valid;
    }
  }

  hasError(field?: AbstractControl, code?: string): boolean {
    let retValue = false;
    if (field) {
      if (field === this.activateUserForm.get('password') && this.showInvalidPasswordAlert()) {
        retValue = true;
      } else if (field === this.activateUserForm.get('code') && this.showInvalidCodeAlert()) {
        retValue = true;
      }
    } else if (this.showActivationFailed()) {
      retValue = true;
    }
    return retValue;
  }


  continueWithAuth() {
    const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute(
      AppConstants.AUHTENTICATION_PROCESS_QUERY_PARAMS)
    ;
    this.logger.debug('Continue after authenticated %o', t);
    if (typeof t === 'string') {
      this.processingService.show();
      window.location.href = t as string;
    } else if (t) {
      const tParams = t.queryParams;
      this.router.navigate([t.path], {
        queryParams: tParams,
        fragment: t.fragment,
      });
    }
  }
  submit() {
    this.authenticate();
  }

  private reset() {

    this.activateUserForm.reset();

    this.invalidPasswordError = undefined;
    this.tooManyAttemptsError = undefined;
    this.tooManyAttemptsTimeout = undefined;
    if (this.tooManyAttemptsTimeoutCounter) {
      this.tooManyAttemptsTimeoutCounter.unsubscribe();
    }
    this.tooManyAttemptsTimeoutCounter = undefined;
    this.invalidCodeError = undefined;
    this.genericError = undefined;
  }


  private readParams(anim?: boolean): Promise<ActivateComponentParams> {
    return new Promise<ActivateComponentParams>((resolve) => {
      const usernamePromise = this.paramsService.getParam(AppConstants.QP_EMAIL);
      const activationKeyPromise = this.paramsService.getParam(AppConstants.QP_USER_ACTIVATION_KEY);


      Promise.all([
        usernamePromise,
        activationKeyPromise,
      ]).then((values) => {
        const result: ActivateComponentParams = {
          username: values[0],
          activationKey: values[1],
        };
        if (result.activationKey) {
          this.activateUserForm.get('code').setValue(result.activationKey);
        }

        if (result.username) {
          this.activateUserForm.get('email').setValue(result.username);
        }
        resolve(result);
      });
    });
  }

  private authenticate() {
    const userName = this.activateUserForm.get('email').value;
    // update auth flows is needed as we need a username specific flow instead of the generic default flow for processing authentications
    this.appStateService.fetchAuthenticationState(userName, (val) => {
      this.appStateService.getAppState().updateAuthenticationState(val);

      let userNameAndPasswordCredential = null;
      userNameAndPasswordCredential = {
        authenticationMethod: AppConstants.AC_AM_RESET_PWD,
        userName: userName,
        password: this.activateUserForm.get('password').value,
        code: this.activateUserForm.get('code').value,
        messageKey: 'ActivateUserComplete'
      } as UserNameAndPasswordCredentialForResetPassword;


      const credentials = [userNameAndPasswordCredential] as Credentials;

      const returnUser = true;
      this.logger.debug('Add authentications (return user: %o): %O',
        returnUser, this.sanitizeCredentials(credentials));
      const addAuthenticationsRequest: Observable<HttpResponseBase> =
        this.appStateService.addAuthentications(credentials, false, returnUser)
          .pipe(catchError(error => ResponseUtil.handleAuthenticationChallengeResponse(error)));
      addAuthenticationsRequest.subscribe(response => {
        this.invalidPasswordError = undefined;
        this.invalidCodeError = undefined;
        this.genericError = undefined;
        this.tooManyAttemptsError = undefined;
        this.tooManyAttemptsTimeout = undefined;
        if (this.tooManyAttemptsTimeoutCounter) {
          this.tooManyAttemptsTimeoutCounter.unsubscribe();
        }
        this.tooManyAttemptsTimeoutCounter = undefined;
        const isErrorResponse = response instanceof HttpErrorResponse;
        if (isErrorResponse) {
          const erResp: HttpErrorResponse = response as HttpErrorResponse;
          this.logger.debug('Login error: %O', erResp);
          this.tooManyAttemptsError = 'authentication_attempts_restricted' === erResp.error.error;
          this.invalidCodeError = 'reset_password_not_authorized' === erResp.error.error;
          this.invalidPasswordError = 'invalid_username_or_password' === erResp.error.error
            ? {} as UserNameAndPasswordChallenge
            : undefined
          ;
          /** @namespace erResp.error.waitSeconds **/
          if (erResp.error.waitSeconds > 2) {
            // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
            this.tooManyAttemptsTimeout = erResp.error.waitSeconds;
            this.tooManyAttemptsTimeoutCounter =  timer(1000, 1000).subscribe(() => {
              this.tooManyAttemptsTimeout--;
              if (this.tooManyAttemptsTimeout <= 0) {
                this.tooManyAttemptsTimeout = undefined;
                this.tooManyAttemptsTimeoutCounter.unsubscribe();
                this.tooManyAttemptsTimeoutCounter = undefined;
              }
            });
          }
        }
        const challenges = ResponseUtil.readChallenges(response).required;
        if (challenges && challenges.length > 0) {
          this.logger.debug('Required challenges received in authentication response: %O', challenges);
          this.invalidPasswordError = challenges.find(challenge => AppConstants.AC_AM_PASSWORD === challenge.authenticationMethod);
        }
        if (this.invalidPasswordError || this.invalidCodeError || this.tooManyAttemptsError) {
          this.genericError = true;
        } else {
          this.continueWithAuth();
        }
      }, err => {
        this.genericError = true;
      });


    }, error => {
      this.genericError = true;
    });
  }

  private validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    const pwField = this.activateUserForm.get('password');
    const cpwField = this.activateUserForm.get('confirmPassword');
    return cpwField.value === pwField.value
      ? null
      : { 'mustMatch': true };
  }

  public hasTooManyAttemptsTimeout(): boolean {
    return this.tooManyAttemptsTimeout > 0;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('activate.window-title');
  }

}
